<template>
	<v-dialog v-model="showModal" content-class="scroll">
		<v-card>
			<v-card-title>Variable List</v-card-title>
			<v-card-text>
				<span
					>You can use these variables in your customized commands! Some of
					these variables are required.</span
				>
				<v-data-table
					:headers="headers"
					:items="items"
					hide-default-footer
					items-per-page=1000
				>
					<template v-slot:item.name="{ item }">
						<span class="var-name">{{ '{' + item.name + '}' }}</span>
					</template>
					<template v-slot:item.example="{ item }">
						"<span class="var-name">{{ '' + item.example + '' }}</span
						>"
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { getVariables } from '@/views/customize/utils/CustomizeUtil.js';

export default {
	name: 'CustomizeFormRequiredModal',
	data: () => ({
		showModal: false,
		headers: [
			{
				text: 'Variable Name',
				value: 'name',
			},
			{
				text: 'Description',
				value: 'description',
			},
			{
				text: 'Example',
				value: 'example',
			},
			{
				text: 'Required?',
				value: 'required',
			},
		],
	}),
	computed: {
		items() {
			return getVariables(
				this.$route.params.command,
				this.$store.getters.selectedForm.type
			);
		},
	},
	methods: {
		show() {
			this.showModal = true;
		},
	},
};
</script>

<style scoped>
.var-name {
	font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
		Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
		Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
	padding: 2.73px;
	background-color: rgb(47, 49, 54);
}
</style>
