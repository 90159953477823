<template>
	<div :class="isMobile ? 'customize-page-mobile' : 'customize-page'">
		<CustomizeVisualizer />
		<CustomizeForm />
	</div>
</template>

<script>
import CustomizeForm from '@/views/customize/components/CustomizeForm';
import CustomizeVisualizer from '@/views/customize/components/CustomizeVisualizer';
import CustomizeMixin from '@/views/customize/mixin/CustomizeMixin.js';

export default {
	mixins: [CustomizeMixin],
	name: 'CustomizeEditorPage',
	components: { CustomizeForm, CustomizeVisualizer },
	computed: {
		command() {
			return this.$route.params.command;
		},
		form() {
			return this.$store.getters.selectedForm;
		},
	},
};
</script>

<style scoped>
.customize-page {
	display: flex;
	flex-grow: 1;
	flex-direction: row-reverse;
	width: 100%;
	height: 100%;
	display: flex;
}

.customize-page-mobile {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	width: 100%;
	min-height: 100%;
	display: flex;
	overflow-x: auto;
}
</style>
